<template>
  <div class="fulfillment-location-list">
    <template
      v-if="fulfillmentCode !== 'sd' && (isPrimary || locations.length)"
    >
      <h2
        class="text-h5 text-weight-bold text-dark text-center text-uppercase"
        v-if="label"
      >
        {{ label }}
      </h2>
      <LocationSkeleton v-show="loadingFirst" />
      <LocationList
        :searchedFulfillment="fulfillment"
        v-show="!loadingFirst"
        :locations="locations"
        :resultByKeyword="resultByKeyword"
        :deliveryAddress="deliveryAddress"
      />
      <EmptyComponent
        v-if="listLoaded && !loading && locations?.length == 0"
        image="images/empty-location.png"
      >
        <p v-if="fulfillmentCode == 'scd'">
          Sorry, the 'Delivery' option is not available for entered
          {{
            askFullAddressForDelivery ? 'address' : 'zip code or city, state'
          }}.
        </p>
        <p v-else>
          Sorry, the 'Store Pickup' option is not available for entered zip code
          or city, state.
        </p>
      </EmptyComponent>
      <div class="row" v-if="hasNext">
        <q-btn
          unelevated
          no-caps
          rounded
          text-color="grey-7"
          class="q-mx-auto"
          size="18px"
          padding="8px 24px"
          @click="loadMore"
          :loading="loading"
        >
          Load More
          <q-icon
            name="keyboard_double_arrow_down"
            size="18px"
            class="q-ml-sm"
          />
        </q-btn>
      </div>
    </template>
    <template v-else-if="fulfillmentCode === 'sd' && listLoaded">
      <template v-if="isPrimary && locations.length == 0">
        <div class="q-my-xl">
          <EmptyComponent image="images/empty-location.png">
            <p>
              Sorry, the 'Standard Shipping' option is not available for entered
              zip code or city, state.
            </p>
          </EmptyComponent>
        </div>
      </template>
      <template v-if="!isPrimary && locations.length">
        <div class="container">
          <h2
            class="text-h5 text-weight-bold text-dark text-center text-uppercase"
            v-if="label"
          >
            {{ label }}
          </h2>
          <div class="bg-green-1 q-pa-md r-8">
            <div
              class="row q-col-gutter-md items-center"
              :style="$q.screen.lt.md ? 'text-align:center;' : ''"
            >
              <div class="col-12 col-md text-subtitle1 text-dark">
                Standard Shipping available for {{ getSearchedZip }}.
              </div>
              <!-- <div class="col-12 col-md text-subtitle1 text-dark" v-else>
                Standard Shipping available with current zip code.
              </div> -->
              <div class="col-12 col-md-auto text-center">
                <q-btn
                  unelevated
                  no-caps
                  color="green"
                  label="Switch Fulfillment"
                  @click="switchFulfillment"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

let initialState = {
  page: 1,
  pageSize: 6,
  hasNext: false,
  listLoaded: false,
  locations: [],
  loadingFirst: false,
  loading: false,
  resultByKeyword: null,
}
export default {
  name: 'FulfillmentLocationList',
  props: {
    isPrimary: {
      type: Boolean,
      default: false,
    },
    setFirstLoading: {
      type: [Boolean, Function],
      default: false,
    },
    setError: {
      type: [Boolean, Function],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    fulfillmentCode: {
      type: String,
      default: '',
    },
    fulfillment: {
      type: Object,
      default: () => {
        return {}
      },
    },
    searchObj: {
      type: Object,
      default: null,
    },
    deliveryAddress: {
      type: Object,
      default: null,
    },
    setShowAdditional: {
      type: [Boolean, Function],
      default: false,
    },
    setHasAdditionalOption: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      ...initialState,
    }
  },
  components: {
    LocationList: () => import('components/location/LocationList'),
    LocationSkeleton: () => import('components/location/LocationSkeleton'),
  },
  computed: {
    ...mapGetters('cart', ['currentCartDetails']),
    ...mapGetters('common', ['askFullAddressForDelivery']),
    getSearchedZip() {
      return this.searchObj.searchKeyword || this.deliveryAddress?.zip
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, {
        ...initialState,
      })
    },
    search() {
      if (!this.searchObj.searchKeyword) return

      let reqParam = {
        isStorePickup: false,
        isScheduledDelivery: false,
      }

      let zipCode = '',
        city = '',
        state = '',
        geolocation = ''

      if (this.deliveryAddress?.geoLocation) {
        geolocation = this.deliveryAddress.geoLocation
        zipCode = this.deliveryAddress.zip || ''
        city = this.deliveryAddress.city || ''
        state = this.deliveryAddress.state || ''

        if (this.searchObj.searchKeyword)
          this.resultByKeyword = this.searchObj.searchKeyword
        else if (zipCode) this.resultByKeyword = zipCode
        else
          this.resultByKeyword = `${city}${city && state ? ',' : ''} ${state}`
      } else if (this.searchObj.selGSuggestion) {
        let gSuggestion = this.searchObj.selGSuggestion?.description?.split(',')

        if (gSuggestion.length > 1) {
          city = gSuggestion[0] || ''
          state = gSuggestion[1] || ''
        } else city = this.searchObj.selGSuggestion?.description || ''

        this.resultByKeyword = `${city}${city && state ? ',' : ''} ${state}`
      } else if ((this.validateZip(this.searchObj.searchKeyword), false)) {
        zipCode = this.searchObj.searchKeyword
        this.resultByKeyword = zipCode
      } else {
        city = this.searchObj.searchKeyword
        this.resultByKeyword = city
      }

      if (this.fulfillmentCode === 'sd') {
        reqParam = {
          zipCode,
          city,
          state,
        }
      } else if (this.fulfillmentCode == 'sp') {
        reqParam = {
          ...reqParam,
          withinMiles: this.searchObj.withinMiles,
          searchKeyword: zipCode,
          city,
          state,
          requestUniqueKey: 'sp',
          page: this.page,
          pageSize: this.pageSize,
          isStorePickup: true,
          geolocation,
        }
      } else if (this.fulfillmentCode == 'scd') {
        reqParam = {
          ...reqParam,
          requestUniqueKey: 'scd',
          page: this.page,
          pageSize: this.pageSize,
          isScheduledDelivery: true,
          searchKeyword: zipCode,
          city,
          state,
          geolocation,
        }
      }

      this.loading = true
      if (this.page == 1) {
        this.loadingFirst = true
        this.setFirstLoading && this.setFirstLoading(true)
      }

      this.$store
        .dispatch(
          this.fulfillmentCode == 'sd'
            ? 'location/verifyShipping'
            : 'location/searchLocation',
          reqParam
        )
        .then((data) => {
          if (data.success && data.data) {
            if (this.fulfillmentCode === 'sd') {
              this.locations = [{ ...data.data }]
              this.setError && this.setError(false)
              this.hasNext = false

              if (this.isPrimary) this.switchFulfillment()

              this.setHasAdditionalOption && this.setHasAdditionalOption(true)
            } else {
              this.page === 1 &&
                data.data?.items?.length &&
                this.setHasAdditionalOption &&
                this.setHasAdditionalOption(true)

              this.locations = this.locations.concat(data.data?.items || [])
              this.hasNext = data.data?.hasNext || false
            }
          } else {
            if (this.fulfillmentCode == 'sd') {
              // this.setError && this.setError(data.message)
              this.setError && this.setError(false)
              this.setShowAdditional && this.setShowAdditional(true)
            }
          }
        })
        .finally(() => {
          this.loading = false
          this.loadingFirst = false
          this.listLoaded = true
          this.setFirstLoading && this.setFirstLoading(false)
        })
    },
    loadMore() {
      this.page += 1
      this.search()
    },
    switchFulfillment() {
      if (this.fulfillmentCode === 'sd' && this.locations[0])
        this.$store.dispatch('location/setAsMyStore', {
          location: this.locations[0],
          fulfillment: this.fulfillment,
          selectedZipCode: this.resultByKeyword,
        })
    },
  },
}
</script>
